<template>
  <div>
    <h2>
      User data deletion
    </h2>
    <p>
      You can inform the applicant to request user account deletion in tryfree.gg by e-mail contact@tryfree.gg
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>